<template>
  <div>
    <div class="grid-x">
      <div
        v-for="post in postList"
        :key="post.id"
        class="cell medium-4"
      >
        <whats-on-card :post="post"/>
      </div>
    </div>
    <div class="more-contain">
      <button class="more" v-if="!maxedPages" @click="fetchPosts">Load More</button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import WhatsOnCard from '@/components/WhatsOnCard';

  export default {
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    components: {
      WhatsOnCard
    },
    mounted() {
      this.setNavigationPadding();
      this.fetchPosts();
    },
    destroyed() {
      this.setNavigationPadding(false);
    },
    methods: {
      ...mapActions(['fetchPosts']),
      ...mapMutations(['setNavigationPadding'])
    },
    computed: {
      postList() {
        return this.$store.state.postList;
      },
      maxedPages() {
        return this.$store.state.postListPage >= this.$store.state.postListPageMax;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .more-contain {
    display: block;
    width: 100%;
    text-align: center;
  }
  .more {
    font: bold #{vw-relative(16, 320)}/1 Brown;
    padding: 2em 0;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(16);
    }
    &:after {
      content: '';
      vertical-align: bottom;
      display: inline-block;
      background-image: url('~@/assets/images/arrow.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: .5em;
      width: 1em;
      height: 1em;
    }
  }
</style>
